import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

const Twitter = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="32"
            height="27"
            viewBox="0 0 32 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M31.9373 3.0972C30.7574 3.61585 29.4974 3.96917 28.1708 4.13049C29.5228 3.31586 30.5627 2.03192 31.0547 0.499979C29.7868 1.23995 28.3815 1.77859 26.8855 2.07858C25.6909 0.799967 23.9883 0 22.0977 0C18.4752 0 15.538 2.93721 15.538 6.55573C15.538 7.07571 15.598 7.57568 15.7073 8.05433C10.2542 7.79701 5.42111 5.17845 2.18658 1.21995C1.61727 2.18258 1.29861 3.3012 1.29861 4.51981C1.29861 6.79972 2.45856 8.80363 4.21582 9.98092C3.13987 9.94625 2.12791 9.65026 1.24528 9.15962V9.24095C1.24528 12.4208 3.50252 15.0727 6.5064 15.6767C5.95575 15.8247 5.37444 15.9047 4.77847 15.9047C4.35982 15.9047 3.9585 15.8647 3.55719 15.79C4.39848 18.3939 6.81705 20.2925 9.6956 20.3458C7.45569 22.1044 4.61714 23.1524 1.55993 23.1524C1.03996 23.1524 0.521312 23.1217 0 23.063C2.91855 24.9216 6.35707 26.0083 10.0756 26.0083C22.1471 26.0083 28.7401 16.014 28.7401 7.36103C28.7401 7.08237 28.7401 6.80105 28.7201 6.52106C30.0014 5.60243 31.12 4.44115 32 3.12387L31.9373 3.0972Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default Twitter;
