import { SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import Link from "next/link";
import React from "react";

const WhiteButtonStyles: SxProps = {
    color: "white",
    "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
};

const PrimaryButtonStyles: SxProps = {
    color: "primary.onContainer",
};

type Props = {
    text: string;
    route: string;
    white: boolean;
};

const LinkButton = ({ text, route, white }: Props) => {
    return (
        <Button
            LinkComponent={Link}
            href={route}
            {...(white
                ? {
                      sx: WhiteButtonStyles,
                  }
                : {
                      sx: PrimaryButtonStyles,
                  })}
        >
            {text}
        </Button>
    );
};

export default LinkButton;
