import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

const Youtube = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.0016 20L20 16L14.0016 12V20ZM26.0016 0H6.00163C2.68735 0 0 2.68735 0 6.00163V26.0016C0 29.3127 2.68735 32 6.00163 32H26.0016C29.3127 32 32 29.3127 32 26.0016V6.00163C32 2.68735 29.3127 0 26.0016 0ZM25.8286 19.582C25.7208 20.9143 24.7118 22.6155 23.2947 22.8604C18.769 23.2098 13.3976 23.1673 8.70531 22.8604C7.24245 22.6775 6.27918 20.911 6.17143 19.582C5.94286 16.7869 5.94286 15.1935 6.17143 12.3984C6.27918 11.0694 7.26531 9.31592 8.70531 9.15592C13.342 8.76735 18.7429 8.84898 23.2947 9.15592C24.9273 9.21469 25.7208 10.8931 25.8286 12.2253C26.0571 15.0204 26.0571 16.7869 25.8286 19.582Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default Youtube;
