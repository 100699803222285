"use client";
import React from "react";
import { SxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Image from "next/image";
import LinkButton from "../navigation/LinkButton";
import Instagram from "../customIcons/Instagram";
import Facebook from "../customIcons/Facebook";
import Twitter from "../customIcons/Twitter";
import Youtube from "../customIcons/Youtube";

const StyledCard = styled(Card)(({ theme }) => ({
    background: `linear-gradient(109deg, ${theme.palette.primary.main} 25.88%, ${theme.palette.secondary.onContainerDim} 87.12%)`,
    padding: "16px",
    borderRadius: "20px",
    boxShadow: theme.shadows[3],
    [theme.breakpoints.only("xs")]: {
        maxWidth: "calc(100vw - 24px)",
    },
    [theme.breakpoints.up("sm")]: {
        maxWidth: "calc(100vw - 16px)",
    },
}));

const AddressContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.only("xs")]: {
        justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-start",
    },
    width: "100%",
    height: "100%",
    ".imageContainer": {
        [theme.breakpoints.only("xs")]: {
            flexBasis: "128px",
            minHeight: "28px",
        },
        [theme.breakpoints.only("sm")]: {
            flexBasis: "160px",
            minHeight: "36px",
        },
        [theme.breakpoints.only("md")]: {
            flexBasis: "200px",
            minHeight: "45px",
        },
        [theme.breakpoints.only("lg")]: {
            flexBasis: "240px",
            minHeight: "54px",
        },
        [theme.breakpoints.only("xl")]: {
            flexBasis: "320px",
            minHeight: "72px",
        },
        position: "relative",
        img: {
            objectFit: "contain",
        },
    },
}));

const iconContainerStyles: SxProps = {
    display: "flex",
    justifyContent: {
        xs: "center",
        sm: "center",
        md: "flex-end",
        lg: "flex-end",
        xl: "flex-end",
    },
};

const linkContainerStyles: SxProps = {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "8px",
    justifyContent: {
        xs: "center",
        sm: "flex-end",
        md: "center",
        lg: "center",
        xl: "center",
    },
};

const Footer = () => {
    const socialLinks: {
        title: string;
        icon: React.ReactNode;
        route: string;
    }[] = [
        {
            title: "Instagram",
            icon: <Instagram />,
            route: "https://www.instagram.com/aiendurance/",
        },
        {
            title: "Facebook",
            icon: <Facebook />,
            route: "https://www.facebook.com/aiendurance",
        },
        {
            title: "Twitter",
            icon: <Twitter />,
            route: "https://twitter.com/aiendurance",
        },
        {
            title: "YouTube",
            icon: <Youtube />,
            route: "https://www.youtube.com/channel/UCH5kdI_s8qIk6EGi3pSfQ-g",
        },
    ];

    const redirect = (route: string) => {
        window.open(route, "_blank", "noopener noreferrer");
    };

    return (
        <StyledCard>
            <Grid container alignItems="center">
                <Grid xs={12} sm={6} md={4}>
                    <AddressContainer>
                        <Box className="imageContainer">
                            <Image
                                src="/images/logo-white-large.svg"
                                alt="AI Endurance Logo"
                                fill
                            />
                        </Box>
                        <Typography
                            variant="body2"
                            color="white"
                            marginLeft="16px"
                            textAlign="center"
                        >
                            175 Longwood Rd S<br />
                            Hamilton, ON L8P 0A1
                        </Typography>
                    </AddressContainer>
                </Grid>
                <Grid xs={12} sm={6} md={4} sx={linkContainerStyles}>
                    <LinkButton white text="FAQ" route="/faq" />
                    <LinkButton white text="API" route="/partnerapi" />
                    <LinkButton white text="Blog" route="/blog" />
                    <LinkButton white text="Terms" route="/termsofuse" />
                    <LinkButton white text="Privacy" route="/privacypolicy" />
                </Grid>
                <Grid xs={12} sm={6} md={4} sx={iconContainerStyles}>
                    {socialLinks.map((link, index) => (
                        <IconButton
                            title={link.title}
                            key={index}
                            onClick={() => redirect(link.route)}
                        >
                            {link.icon}
                        </IconButton>
                    ))}
                </Grid>
            </Grid>
        </StyledCard>
    );
};

export default Footer;
