import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import React from "react";

const Facebook = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M32 16.0001C32 7.16345 24.8366 0 16.0001 0C7.16345 0 0 7.16345 0 16.0001C0 23.9862 5.85098 30.6054 13.5 31.8057V20.6251H9.43752V16.0001H13.5V12.475C13.5 8.46502 15.8887 6.25001 19.5434 6.25001C21.294 6.25001 23.125 6.56251 23.125 6.56251V10.5H21.1075C19.1199 10.5 18.5 11.7334 18.5 12.9987V16.0001H22.9375L22.2282 20.6251H18.5V31.8057C26.1491 30.6054 32 23.9862 32 16.0001Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default Facebook;
